






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { View, ViewItem, ViewItemType } from '@/models'
import * as componentTypes from '@/components/componentTypes'
import ViewLayout from './ViewLayout.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { Debounce } from '@/utils/decorators'
import runtimeParams from '@/utils/runtime/runtimeParams'

interface PendingOperation {
  operation : 'add' | 'remove' | 'update'
  priority : number
  path : number[]
  data : any
}

@Component({
  components: {
    ViewLayout
  }
})
export default class EnvironmentViewComponents extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string
  @Prop({ type: Object, required: true }) view !: View
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, any>
  @Prop({ type: Boolean, default: false }) editing !: boolean
  @Prop({ type: Boolean, default: false }) preview !: boolean
  @Prop({ type: Boolean, default: false }) forceMobile !: boolean

  get params () {
    return { ...this.viewParams, ...this.runtimeParams }
  }

  runtimeParams = {}
  operationQueue : PendingOperation[] = []

  mounted () {
    runtimeParams.get = () => this.runtimeParams
    runtimeParams.set = this.handleSetParams.bind(this)
  }

  beforeDestroy () {
    runtimeParams.get = () => ({})
    runtimeParams.set = (params : Record<string, any>) => {}
  }

  @Debounce()
  runPendingOperations () {
    const sortedOperations = this.operationQueue.sort((a, b) => b.priority - a.priority)
    this.operationQueue = []
    const clonedView = _cloneDeep(this.view)

    sortedOperations.forEach(o => {
      const path = o.operation === 'update' ? o.path : o.path.slice(0, -1)
      const target = path.reduce((a : any, b : number) => {
        return a.subItems[b]
      }, { subItems: clonedView.items }) as ViewItem
      switch (o.operation) {
        case 'update':
          console.log('Updated Item at', o.path.join(' -> '))
          Object.assign(target, o.data)
          break
        case 'add':
          console.log('Added Item at', o.path.join(' -> '))
          if (!target.subItems) target.subItems = []
          target.subItems?.splice(o.path.slice(-1)[0], 0, o.data)
          break
        case 'remove':
          console.log('Removed Item at', o.path.join(' -> '))
          target.subItems?.splice(o.path.slice(-1)[0], 1)
          break
      }
    })

    console.log('Updating view...')
    this.$emit('update:view', clonedView)
  }

  handleItemUpdate (path : number[], delta : Partial<ViewItem>) {
    this.operationQueue.push({ operation: 'update', priority: path.length, path, data: delta })
    this.runPendingOperations()
  }

  handleItemAdd (path : number[], data : ViewItem) {
    this.operationQueue.push({ operation: 'add', priority: path.length, path, data })
    this.runPendingOperations()
  }

  handleItemRemove (path : number[]) {
    this.operationQueue.push({ operation: 'remove', priority: path.length, path, data: null })
    this.runPendingOperations()
  }

  handleSubItemsUpdate (data : ViewItem[]) {
    if (data.length !== this.view.items.length) return
    console.log('Handle SubItems Update', data)
    const clonedView = _cloneDeep(this.view)
    clonedView.items = data
    this.$emit('update:view', clonedView)
  }

  handleEditComponent (type : string, id : string, path : string[]) {
    this.$emit('editComponent', type, id, path)
  }

  handleSetParams (params : Record<string, any>) {
    Object.keys(params).forEach(pk => {
      this.$set(this.runtimeParams, pk, params[pk])
    })
  }
}
